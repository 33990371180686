/* CSS Reset */
* {
  margin: 0;
  padding: 0;
}

.background {
  width: 100%;
  height: 100%;
  background-image: url(errornotfound_bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
}

.road {
  background-image: url(errornotfound_road.png);
  background-repeat: repeat-x;
  position: absolute;
  bottom: -165px;
  width: 1000vw;
  height: 20vh;
  animation: roadanimation 60s linear infinite;
}

.truck {
  background-image: url(errornotfound_truck.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 27rem;
  height: 9.699rem;
  position: absolute;
  bottom: 40px;
  left: 0px;
}

.wheel {
  position: absolute;
  animation: wheelanimation linear 0.6s infinite;
}
.wheel1 {
  background-image: url(errornotfound_wheel.png);
  background-size: contain;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  bottom: 45px;
  left: 96px;
}
.wheel2 {
  background-image: url(errornotfound_wheel.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  bottom: 45px;
  left: 66px;
}
.wheel3 {
  background-image: url(errornotfound_wheel.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  bottom: 45px;
  left: 264px;
}
.wheel4 {
  background-image: url(errornotfound_wheel.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  bottom: 45px;
  left: 330px;
}

@keyframes wheelanimation {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes roadanimation {
  100% {
    transform: translate(-500vw);
  }
}
