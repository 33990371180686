.bg {
  width: 100%;
  height: 100%;
  background-image: url(erroraccessdenied_bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.chuck {
  background-image: url(chuck_right.png);
  display: block;
  width: 50px;
  height: 53px;
  position: absolute;
  left: 53%; /* Center horizontally */
  top: 75%; /* Center vertically */
  transform: translate(-50%, -50%) scaleX(-1); /* Centering and turning */
  animation: toReLeft 1s forwards, toDisoriented 0.5s linear 3s infinite forwards;
}

@keyframes toReLeft {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }
  60% {
    opacity: 1;
    transform: translateX(-30px);
  }
  80% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes toDisoriented {
  0%,
  100% {
    background-image: url(chuck_right.png);
  }
  50% {
    background-image: url(chuck_left.png);
  }
}
